import { Button } from '@ri-digital/web-component-library/src/atom/Button';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Close from '../Icons/Close';
import style from './Modal.scss';

const cx = classNames.bind(style);

const Modal = ({ children, eventName }) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener(eventName, openModal);
    return () => {
      window.removeEventListener(eventName, openModal);
    };
  }, [eventName]);

  if (!open) {
    return null;
  }

  return (
    <div className={cx('modal')} data-qa="modal">
      <div className={cx('modal__content')} data-qa="modal-content">
        <Button
          title="close"
          className={cx('modal__close')}
          onClick={closeModal}
          qaTag="modal-close"
        >
          <Close />
        </Button>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  eventName: PropTypes.string.isRequired,
};

export default Modal;
