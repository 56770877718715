import { Button } from '@ri-digital/web-component-library/src/atom/Button';
import classNames from 'classnames/bind';
import React, { useRef } from 'react';

import config from '../../../config/default.json';
import { OPEN_US_MODAL } from '../../constants/eventNames';
import getMainSiteLocation from '../../helpers/getMainSiteLocation';
import useConvertCartLocale from '../../hooks/useConvertCartLocale';
import { TRANSLATIONS as text } from '../../translations';
import Warning from '../Icons/Warning';
import Modal from '../Modal';
import styles from './USRedirectModal.scss';

const cx = classNames.bind(styles);
const { redirectModal } = text;

export default function USRedirectModal() {
  const {
    location: { origin },
  } = window;
  const formRef = useRef(null);

  const envMapped = getMainSiteLocation(config, origin);
  const url = `${envMapped.url}site/Change`;

  const { convertCartLocale } = useConvertCartLocale();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await convertCartLocale({
      country: 'US',
      currency: 'USD',
    });

    formRef?.current?.submit();
  };

  return (
    <Modal eventName={OPEN_US_MODAL}>
      <div className={cx('us-popup')} data-qa="us-modal">
        <Warning />
        <p className={cx('us-popup__header')} data-qa="us-modal-header">
          {redirectModal.header.part1}
          <strong> {redirectModal.header.part2}</strong>
        </p>
        <p className={cx('us-popup__text')} data-qa="us-modal-text">
          {redirectModal.text.part1}
          <strong>{redirectModal.text.part2}</strong>
          {redirectModal.text.part3}
        </p>

        <form
          className={cx('us-popup__form')}
          data-qa="us-form"
          action={url}
          method="POST"
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <input type="hidden" id="country" name="country" value="US" />
          <input type="hidden" id="currency" name="currency" value="USD" />
          <input
            type="hidden"
            id="redirectUrl"
            name="redirectUrl"
            value={origin}
          />
          <Button
            type="submit"
            title="link"
            className={cx('us-popup__btn')}
            qaTag="us-modal-button"
          >
            {redirectModal.link}
          </Button>
        </form>
      </div>
    </Modal>
  );
}
