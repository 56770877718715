import { useLazyQuery } from '@apollo/client';

import { GET_CONVERT_CART } from '../../lib/queries';

const useConvertCartLocale = () => {
  const [convertCartLocaleQuery, { data }] = useLazyQuery(GET_CONVERT_CART);

  const convertCartLocale = ({ country, currency }) => {
    return convertCartLocaleQuery({
      variables: {
        args: {
          locale: {
            country,
            currency,
          },
        },
      },
    });
  };

  return {
    convertCartLocale,
    data,
  };
};

export default useConvertCartLocale;
