import { useReactiveVar } from '@apollo/client';
import React, { ReactNode } from 'react';

import { journeyHasError as journeyHasErrorVar } from '../../lib/reactives';
import { TRANSLATIONS as text } from '../../translations';
import FlashMessage from '../FlashMessage';
import styles from './Container.module.scss';

const {
  flashMessages: { journeyError },
} = text;

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode | ((...args: ReactNode[]) => ReactNode);
}

export function Container({ children, ...rest }: ContainerProps): JSX.Element {
  const journeyHasError = useReactiveVar(journeyHasErrorVar);
  return (
    <div className={styles.container} {...rest} data-qa="container">
      {journeyHasError && <FlashMessage message={journeyError} />}
      {typeof children === 'function' ? children() : children}
    </div>
  );
}

export default Container;
