import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import setNamedTransformationCloudinaryUrl from '../../helpers/setNamedTransformationCloudinaryUrl';
import { deliveryProvider } from '../../lib/reactives';
import { TRANSLATIONS as text } from '../../translations';
import Address from '../Address';
import style from './StoreDetail.scss';

const cx = classNames.bind(style);

const StoreDetail = ({ name, markerLabel, distance, address }) => {
  useEffect(() => {
    if (name) {
      deliveryProvider(name);
    }
  }, []);

  return (
    <div className={cx('store-detail')}>
      <div className={cx('store-detail__header')}>
        {markerLabel && (
          <div
            className={cx('store-detail__marker')}
            data-qa="store-detail-marker"
          >
            <img
              className={cx('store-detail__marker-image')}
              src={setNamedTransformationCloudinaryUrl(
                'https://images.riverisland.com/image/upload/q_auto,w_40/pdp_map_marker.png',
                'CheckoutThumbnail',
              )}
              width="40"
              height="48"
              alt="Map Marker"
            />
            <span className={cx('store-detail__marker-label')}>
              {markerLabel}
            </span>
          </div>
        )}
        <div>
          <h2
            className={cx('store-detail__heading')}
            data-qa="store-name-heading"
          >
            {name}
          </h2>
          {distance && (
            <p
              className={cx('store-detail__distance')}
              data-qa="store-detail-distance"
            >
              {distance} {text.distanceUnit}
            </p>
          )}
          <Address {...address} dataTestId="store-detail-address" />
        </div>
      </div>
    </div>
  );
};

StoreDetail.defaultProps = {
  markerLabel: null,
  distance: null,
};

StoreDetail.propTypes = {
  markerLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  distance: PropTypes.number,
  address: PropTypes.shape().isRequired,
};

export default StoreDetail;
