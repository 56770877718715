import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CartPricePropType } from '../../helpers/prop-types';
import LineItem from './LineItem';
import UnlimitedDelivery from './UnlimitedDelivery';

const ProductRow = ({
  className,
  assets,
  isUnlimitedDelivery,
  name,
  quantity,
  sizeLabel,
  wasTotal,
  total,
}) => {
  const [mainImage, setMainImage] = useState(null);

  useEffect(() => {
    if (assets) {
      setMainImage(
        assets.images.filter((image) => image.type === 'main')[0] || null,
      );
    }
  }, [assets]);

  return (
    <>
      {isUnlimitedDelivery ? (
        <>
          <UnlimitedDelivery
            netPrice={total.formattedValue}
            productImage={mainImage}
            name={name}
          />
        </>
      ) : (
        <LineItem
          className={className}
          total={total}
          productImage={mainImage}
          imageBaseUrl={assets?.imageBaseURL || ''}
          name={name}
          quantity={quantity}
          size={sizeLabel}
          wasTotal={wasTotal}
        />
      )}
    </>
  );
};

ProductRow.propTypes = {
  total: CartPricePropType.isRequired,
  wasTotal: CartPricePropType,
  assets: PropTypes.shape(),
  isUnlimitedDelivery: PropTypes.bool,
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  sizeLabel: PropTypes.string,
  className: PropTypes.string,
};

ProductRow.defaultProps = {
  quantity: null,
  wasTotal: null,
  sizeLabel: '',
  isUnlimitedDelivery: false,
  className: '',
  assets: null,
};

export default ProductRow;
