import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import Layout from '../../components/Layout';
import { CartPricePropType } from '../../helpers/prop-types';
import { useFeaturesConfig } from '../../hooks/useFeaturesConfig';
import { ieShop } from '../../lib/reactives';
import {
  AddressFormPage,
  AddressListPage,
  BFPOFormPage,
  DeliveryMethodPage,
  DeliveryOptionsPage,
  HomeDeliveryPage,
  LocalShopConfirmationPage,
  LocalShopDetailsPage,
  LocalShopSearchPage,
  ReviewAndPayPage,
  StoreDetailsPage,
  StoreErrorPage,
  StoreSearchPage,
} from '../../pages';
import StoreConfirmationPage from '../../pages/StoreConfirmationPage';
import { Routes } from '../routes';
import RoutesWrapper from '../RoutesWrapper';

const MainRoutes = ({ cart, loading, error }) => {
  useFeaturesConfig('IE', {
    skip: !'GB' && !'IE',
    onCompleted: ({ featuresConfig }) => {
      const found = Array.from(featuresConfig)?.find(
        (f) => f.name === 'ShowLocalShopForWeb',
      )?.enabled;
      ieShop({ ShowLocalShopForWebIE: found });
    },
  });

  return (
    <RoutesWrapper>
      <Route path={Routes.storeError.path} element={<StoreErrorPage />} />
      <Route
        path=""
        element={<Layout cart={cart} loading={loading} error={error} />}
      >
        <Route
          path={Routes.deliveryType.path}
          element={
            <DeliveryMethodPage
              analytics={Routes.deliveryType.analytics}
              country={cart?.locale?.country}
            />
          }
        />
        <Route
          path={Routes.homeDelivery.path}
          element={
            <HomeDeliveryPage analytics={Routes.homeDelivery.analytics} />
          }
        />
        <Route
          path={Routes.selectAddress.path}
          element={
            <AddressListPage analytics={Routes.selectAddress.analytics} />
          }
        />
        <Route
          path={Routes.addressForm.path}
          element={
            <AddressFormPage
              country={cart?.locale?.country}
              billingAddress={cart?.billingAddress}
              analytics={Routes.addressForm.analytics}
            />
          }
        />
        <Route
          path={Routes.bfpoForm.path}
          element={<BFPOFormPage analytics={Routes.bfpoForm.analytics} />}
        />
        <Route
          path={Routes.storeSearch.path}
          element={<StoreSearchPage analytics={Routes.storeSearch.analytics} />}
        />
        <Route
          path={Routes.storeDetails.path}
          element={
            <StoreDetailsPage analytics={Routes.storeDetails.analytics} />
          }
        />
        <Route
          path={Routes.storeConfirm.path}
          element={
            <StoreConfirmationPage analytics={Routes.storeConfirm.analytics} />
          }
        />
        <Route
          path={Routes.localShopSearch.path}
          element={
            <LocalShopSearchPage analytics={Routes.localShopSearch.analytics} />
          }
        />
        <Route
          path={Routes.localShopDetails.path}
          element={
            <LocalShopDetailsPage
              analytics={Routes.localShopDetails.analytics}
            />
          }
        />
        <Route
          path={Routes.localShopConfirm.path}
          element={
            <LocalShopConfirmationPage
              analytics={Routes.localShopConfirm.analytics}
            />
          }
        />
        <Route
          path={Routes.deliveryOptions.path}
          element={
            <DeliveryOptionsPage
              analytics={Routes.deliveryOptions.analytics}
              cart={cart}
            />
          }
        />
        <Route
          path={Routes.reviewAndPay.path}
          element={
            <ReviewAndPayPage analytics={Routes.reviewAndPay.analytics} />
          }
        />
      </Route>
    </RoutesWrapper>
  );
};

MainRoutes.propTypes = {
  cart: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    discountCodes: PropTypes.arrayOf(PropTypes.string),
    discountList: PropTypes.arrayOf(PropTypes.shape()),
    subtotal: CartPricePropType.isRequired,
    total: CartPricePropType.isRequired,
    remaining: CartPricePropType,
    totalLineItems: PropTypes.number.isRequired,
    lineItems: PropTypes.arrayOf(PropTypes.shape()),
    paymentMethod: PropTypes.string,
    shippingAddress: PropTypes.shape(),
    billingAddress: PropTypes.shape(),
    deliveryOption: PropTypes.shape(),
    locale: PropTypes.shape({
      country: PropTypes.string,
    }),
  }).isRequired,
  error: PropTypes.shape(),
  loading: PropTypes.bool,
};

MainRoutes.defaultProps = {
  loading: false,
  error: undefined,
};
export default MainRoutes;
