import addToDataLayer from '../../../helpers/addToDataLayer';

const newsLetterSignUp = (checked, subscriptions) => {
  const data = {
    newsletter: {
      subscriptions,
      action: checked ? 'subscribe' : 'unsubscribe',
    },
  };

  addToDataLayer('newsletter', data);
};

export default newsLetterSignUp;
