import React from 'react';
import { Route } from 'react-router-dom';

import { ReviewAndPayPage } from '../../pages';
import { Routes } from '../../routes/routes';
import RoutesWrapper from '../../routes/RoutesWrapper';

const ReviewAndPayRedirectLayout = () => {
  return (
    <RoutesWrapper>
      <Route
        path={Routes.reviewAndPay.path}
        element={<ReviewAndPayPage analytics={Routes.reviewAndPay.analytics} />}
      />
    </RoutesWrapper>
  );
};

export default ReviewAndPayRedirectLayout;
