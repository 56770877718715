import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as mutations from '../../lib/mutations';
import { TRANSLATIONS as text } from '../../translations';
import errorMapper from '../utils/errorMapper';
import { replaceCartCache } from '../utils/replaceCartCache';

export const errorMessages = {
  PromoCodeRejected: text.errors.PromoCodeRejected,
};

export const useAddDiscountCode = (options) => {
  const [addDiscountCode, { loading, called, error, data, reset }] =
    useMutation(mutations.ADD_DISCOUNT_CODE, {
      update: replaceCartCache('addDiscountCode'),
      ...options,
    });

  const location = useLocation();

  useEffect(() => {
    reset();
  }, [location?.pathname, reset]);

  const mutationFn = (mutation) => async (variables) => {
    return mutation({ variables });
  };

  return {
    reset,
    called,
    loading,
    data,
    error: errorMapper(error, errorMessages),
    addDiscountCode: mutationFn(addDiscountCode),
    location,
  };
};
