import addToDataLayer from '../../../helpers/addToDataLayer';

const newsLetterSignUp = (checked, subscriptions) => {
  const data = {
    subscriptions,
    action: checked ? 'subscribe' : 'unsubscribe',
  };

  addToDataLayer('newsLetter', data);
};

export default newsLetterSignUp;
