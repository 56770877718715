import DefaultImage from '@ri-digital/web-component-library/src/atom/DefaultImage';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import setNamedTransformationCloudinaryUrl from '../../helpers/setNamedTransformationCloudinaryUrl';
import style from './ProductRow.scss';

const cx = classNames.bind(style);

const ProductImage = ({ name, productImage }) => (
  <>
    {productImage ? (
      <img
        src={setNamedTransformationCloudinaryUrl(
          productImage.id,
          'CheckoutThumbnail',
        )}
        width="150"
        height="150"
        className={cx('mybag__product--image')}
        alt={name}
        data-qa="product-image"
      />
    ) : (
      <DefaultImage
        className={cx('mybag__product--image')}
        data-qa="default-image"
      />
    )}
  </>
);

ProductImage.propTypes = {
  productImage: PropTypes.shape(),
  name: PropTypes.string.isRequired,
};

ProductImage.defaultProps = {
  productImage: null,
};

export default ProductImage;
