import { Cloudinary } from '@cloudinary/url-gen';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';
import { auto } from '@cloudinary/url-gen/qualifiers/quality';

const setNamedTransformationCloudinaryUrl = (url, transformation) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: '',
    },
    url: {
      secureDistribution: 'images.riverisland.com',
      secure: true,
      privateCdn: true,
      analytics: false,
      shorten: false,
    },
  });

  const splitURL = url.split('/');
  const lastPart = splitURL.pop();
  const decoded = decodeURI(lastPart.split('?')[0]);
  const image = cld.image(decoded);

  return image
    .namedTransformation(name(transformation))
    .delivery(format(auto()))
    .delivery(quality(auto()))
    .toURL();
};

export default setNamedTransformationCloudinaryUrl;
