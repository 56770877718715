import { makeVar } from '@apollo/client';

const featuresConfig = makeVar([]);
const isWebview = makeVar(false);
const appOs = makeVar(null);

const journeyHasError = makeVar(false);
const cartLocation = makeVar('CHECKOUT');
const selectedDeliveryMethod = makeVar('home');
const currentRoute = makeVar({
  stepId: null,
  stepIndex: null,
  stepName: '',
});

const userProps = makeVar({});
const ieShop = makeVar({});
const collectFromStores = makeVar({ stores: [], searchQuery: '' });
const deliveryProvider = makeVar('');

const isUpdateBackendCartLoadingVar = makeVar(false);
const redirectToGlobalE = makeVar(false);

export {
  appOs,
  cartLocation,
  collectFromStores,
  currentRoute,
  deliveryProvider,
  featuresConfig,
  ieShop,
  isUpdateBackendCartLoadingVar,
  isWebview,
  journeyHasError,
  redirectToGlobalE,
  selectedDeliveryMethod,
  userProps,
};
