import { gql } from '@apollo/client';

const PRICE_FRAGMENT = gql`
  fragment Price on CartPrice {
    value
    formattedValue
    currencyCode
  }
`;

export const CART_FIELDS_FRAGMENT = gql`
  fragment AllCartFields on Cart {
    id
    customerId
    email
    lineItems {
      id
      productId
      sizeKey
      sizeLabel
      price {
        value
        formattedValue
        currencyCode
      }
      total {
        value
        formattedValue
        currencyCode
      }
      wasTotal {
        value
        formattedValue
        currencyCode
      }
      totalDiscount {
        value
      }
      name
      quantity
      imageUrl
      assets {
        images {
          type
          id
        }
        imageBaseURL
      }
      slug
      primaryBreadcrumbs
      brands
      stock
      skuCode
      color
      brands
      description
      totalDiscount {
        formattedValue
        value
        currencyCode
      }
    }
    totalLineItems
    totalQuantity
    total {
      value
      formattedValue
      currencyCode
      centAmount
    }
    remaining {
      value
      formattedValue
      centAmount
      currencyCode
    }
    subtotal {
      value
      formattedValue
      currencyCode
    }
    discountList {
      name
      value {
        value
        formattedValue
        currencyCode
      }
    }
    discountCodes
    shippingAddress {
      addressType
      title
      firstName
      lastName
      addressLine1
      addressLine2
      addressLine3
      city
      postalCode
      country
      phone
      deliveryInstruction
      isBfpo
    }
    billingAddress {
      title
      firstName
      lastName
      addressLine1
      addressLine2
      addressLine3
      city
      state
      postalCode
      country
      phone
    }
    giftcards {
      id
      amount {
        value
        formattedValue
        currencyCode
      }
      number
      balance {
        value
        formattedValue
        currencyCode
      }
    }
    deliveryOption {
      serviceLevel
      customerCollectionDate
      startTime
      endTime
      price {
        value
        formattedValue
        currencyCode
      }
    }
    shippingPass {
      product {
        productId
        sizeKey
        name
        price {
          value
          formattedValue
        }
        imageUrl
      }
      active
      expiresOn
    }
    giftcardTotal {
      value
      formattedValue
      currencyCode
    }
    checkoutFlow
    locale {
      country
      currency
    }
  }
`;

export const GIFT_CARD_FIELDS_FRAGMENT = gql`
  ${PRICE_FRAGMENT}
  fragment GiftCardFields on Cart {
    id
    total {
      ...Price
      centAmount
    }
    remaining {
      ...Price
      centAmount
    }
    subtotal {
      ...Price
    }
    giftcards {
      id
      amount {
        ...Price
      }
      number
      balance {
        ...Price
      }
    }
    giftcardTotal {
      ...Price
    }
  }
`;

export const ORDER_FIELDS_FRAGMENT = gql`
  fragment OrderFields on Order {
    id
    status
    customerId
    paymentMethods
    billingAddress {
      city
      country
    }
    lineItems {
      id
      productId
      productType
      sizeKey
      sizeLabel
      total {
        formattedValue
        value
        currencyCode
      }
      wasTotal {
        formattedValue
        value
      }
      totalDiscount {
        value
      }
      name
      quantity
      imageUrl
      assets {
        images {
          type
          id
        }
        imageBaseURL
      }
      slug
      price {
        value
      }
      primaryBreadcrumbs
      brands
      stock
      skuCode
      color
      brands
    }
    totalLineItems
    totalQuantity
    total {
      formattedValue
      value
    }
    subtotal {
      formattedValue
      value
    }
    remaining {
      formattedValue
      value
    }
    discountList {
      name
      value {
        formattedValue
        value
      }
    }
    totalDiscountExcludingShipping {
      value
    }
    discountCodes
    shippingAddress {
      addressType
      title
      firstName
      lastName
      addressLine1
      addressLine2
      addressLine3
      city
      postalCode
      country
      phone
      deliveryInstruction
      isBfpo
    }
    deliveryOption {
      serviceLevel
      customerCollectionDate
      startTime
      endTime
      price {
        formattedValue
        value
      }
    }
    giftcardTotal {
      formattedValue
      value
    }
    email
    createdAt
    locale {
      country
      currency
    }
  }
`;
